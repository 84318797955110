import { render, staticRenderFns } from "./ridercollection.vue?vue&type=template&id=0d67b80f&scoped=true"
import script from "./ridercollection.vue?vue&type=script&lang=js"
export * from "./ridercollection.vue?vue&type=script&lang=js"
import style0 from "./ridercollection.vue?vue&type=style&index=0&id=0d67b80f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d67b80f",
  null
  
)

component.options.__file = "ridercollection.vue"
export default component.exports